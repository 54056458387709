<template>
  <base-layout page-title="My Pages" :showBackButton="showSystemBackButton">
    <ion-refresher
      slot="fixed"
      @ionRefresh="doRefresh($event)"
      :disabled="inPostRefreshMode"
    >
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-row class="ion-no-padding ion-no-pargin">
      <ion-col
        :size="getColSize()"
        :offset="getOffsetSize()"
        class="ion-no-padding ion-no-pargin"
      >
        <ion-item v-if="!creatingNewPage && !updatingPage" no-lines lines="none">
          <ion-button
            slot="end"
            style="text-transform: none; margin-right: 15px;"
            @click="creatingNewPage=true"
          >
            Create New Page
          </ion-button>
        </ion-item>
        <span v-if="creatingNewPage || updatingPage">
          <!-- create/update page -->
          <ion-row>
            <ion-col class="ion-no-padding ion-no-margin">
              <form @submit.prevent="handleNewPage">
                <ion-item no-lines lines="none" class="signup-item-style">
                  <ion-label class="left-margin-15px">{{ getStrings(strings, 'Page Name') }}:</ion-label><br/>
                  <ion-input
                    v-model="form.pageName"
                    id="pageName"
                    class="right-margin-15px"
                    required
                    placeholder="Min 10 chars, Max 50 char, and Min 2 words"
                  ></ion-input>
                </ion-item>
                <ion-item no-lines lines="none" class="signup-item-style">
                  <ion-label class="left-margin-15px">{{ getStrings(strings, 'Page Description') }}:</ion-label>
                  <ion-textarea
                    auto-grow="true"
                    autocomplete="on"
                    autocapitalize="on"
                    spellcheck="true"
                    rows="1"
                    v-model="form.pageDescription"
                    id="pageDescription"
                    required
                    no-padding
                    no margin
                    class="right-margin-15px"
                    placeholder="Write Page description"
                  ></ion-textarea>
                </ion-item>
                <ion-item no-lines lines="none" class="signup-item-style">
                  <ion-label class="left-margin-15px">{{ getStrings(strings, 'Is Page Searchable') }}:</ion-label>
                  <ion-checkbox
                    id="pageSearchable"
                    slot="end"
                    style="margin-right: 20px"
                    v-model="form.pageSearchable"
                    required
                  ></ion-checkbox>
                </ion-item>
                <ion-item>
                  <ion-button
                    slot="end"
                    color="primary"
                    :disabled="newPageClicked"
                    @click="creatingNewPage=false, updatingPage=false"
                    style="text-transform: none;"
                  >
                    {{ getStrings(strings, 'Cancel') }}
                  </ion-button>
                  <ion-button
                    slot="end"
                    color="primary"
                    :disabled="newPageClicked"
                    type="submit"
                    style="text-transform: none;"
                    class="right-margin-15px"
                    v-if="creatingNewPage"
                  >
                    {{ getStrings(strings, 'Create') }}
                  </ion-button>
                  <ion-button
                    slot="end"
                    color="primary"
                    :disabled="newPageClicked"
                    type="submit"
                    style="text-transform: none;"
                    class="right-margin-15px"
                    v-if="updatingPage"
                  >
                    {{ getStrings(strings, 'Update') }}
                  </ion-button>
                  <ion-spinner v-if="newPageClicked" name="dots"></ion-spinner>
                </ion-item>
              </form>
            </ion-col>
          </ion-row>
        </span>
        <span v-if="myPageList.length === 0 && !creatingNewPage && !updatingPage">
          <ion-item no-lines lines="none" class="ion-no-padding ion-no-margin">
            <ion-label style="margin-left: 15px">
              <b>You do not have any pages</b>
            </ion-label>
          </ion-item>
        </span>
        <span v-if="myPageList.length > 0 && !creatingNewPage && !updatingPage">
          <ion-item no-lines lines="none" class="ion-no-padding ion-no-margin">
            <ion-label style="margin-left: 15px">
              <b>My Pages</b>
            </ion-label>
          </ion-item>
          <ion-list class="ion-no-padding ion-no-margin" v-for="page in myPageList" v-bind:key="page.id">
            <ion-item>
              <ion-avatar
                slot="start"
                class="left-margin-15px"
                style="cursor: pointer"
                @click="navigatePage(page.tokenized_group)"
              >
                <ion-img
                  v-if="page && !page.photo_thumbnail"
                  src="assets/icon/misc/avatar.svg"
                ></ion-img>
                <ion-img
                  size="small"
                  v-if="page && page.photo_thumbnail"
                  :src="page.photo_thumbnail"
                ></ion-img>
              </ion-avatar>
              <ion-label
                class="ion-text-wrap"
                style="cursor: pointer; margin-left: 15px;"
                @click="navigatePage(page.tokenized_group)"
              >
                <b>{{ page.group_name }}</b>
                <p>{{ page.description }}</p>
                <p><b>{{ page.member_count }}</b> Page Followers</p>
              </ion-label>
            </ion-item>
            <ion-item no-lines lines="none">
              <ion-buttons slot="end">
              <ion-button
                fill="clear"
                style="text-transform: none; margin-right: 15px"
                @click="onUpdatePage(page)"
              >
                Update
              </ion-button>
              <ion-button
                fill="clear"
                style="text-transform: none; margin-right: 15px"
                @click="confirmDeletePage(page)"
              >
                Delete
              </ion-button>
              </ion-buttons>
            </ion-item>
          </ion-list>
        </span>
      </ion-col>
    </ion-row>
  </base-layout>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonRefresher,
  IonRefresherContent,
  IonImg,
  IonButton,
  IonButtons,
  IonLabel,
  IonList,
  IonItem,
  IonAvatar,
  IonSpinner,
  IonInput,
  IonTextarea,
  IonCheckbox,
  toastController,
  alertController,
} from "@ionic/vue";

import { useRouter } from "vue-router";
import { homeOutline, cameraOutline, lockClosedOutline, lockOpenOutline } from "ionicons/icons";

import { getColSize, getOffsetSize, logOffApp } from "../services/utils";
import {  readFromDB } from "../services/db";
import { listGroups, createGroup, updateGroup, deactivateGroup } from "../services/apiCall";
import { getStrings } from "../services/lang";

export default {
  name: "ProessionalPage",
  ionViewDidEnter() {
    this.profPageLeft = false;
  },
  ionViewDidLeave() {
    this.profPageLeft = true;
  },
  ionViewWillEnter() {
    this.profPageLeft = false;
    this.readDBAndInit();
  },
  ionViewWillLeave() {
    this.profPageLeft = true;
  },
  mounted() {
    this.profPageLeft = false;
  },
  components: {
    IonRow,
    IonCol,
    IonRefresher,
    IonRefresherContent,
    IonImg,
    IonButton,
    IonButtons,
    IonLabel,
    IonList,
    IonItem,
    IonAvatar,
    IonSpinner,
    IonInput,
    IonTextarea,
    IonCheckbox,
  },
  setup() {
    const router = useRouter();
    return {
      router,
      getColSize,
      getOffsetSize,
      getStrings,
      createGroup,
      updateGroup,
    };
  },
  data() {
    return {
      icons: {
        homeOutline,
        cameraOutline,
        lockClosedOutline,
        lockOpenOutline
      },
      currentPageProfile: {
        photo: "",
        photo_thumbnail: "",
      },

      inPostRefreshMode: false,
      refreshEventObject: null,

      profPageLeft: false,
      showSystemBackButton: true,
      uploadingPhoto: false,
      createPostTopics: [],
      topicsPerPostLimit: 2,
      fetchingPageList: false,
      myPageList: [],
      creatingNewPage: false,
      updatingPage: false,
      newPageClicked: false,
      errors: [],
      page: {},
      pageToUpdate: {},
      form: {
        pageName: "",
        pageDescription: "",
        pageVisibility: "Professional_Page",
        pageMemberJoining: "Open",
        pageSearchable: false,
      },
    };
  },
  computed: {
    strings() {
      return this.$store.getters.strings;
    },
    user() {
      return this.$store.getters.user;
    },
    posts() {
      return this.$store.getters.posts.filter(
        (p) => p.post_origin_tag === "Group"
      );
    },
  },
  methods: {
    async openToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 1500,
      });
      return toast.present();
    },
    async showMessage(msgHeader, msg) {
      (
        await alertController.create({
          header: msgHeader,
          message: msg,
          buttons: ["OK"],
        })
      ).present();
    },
    doRefresh(ev) {
      this.refreshEventObject = ev;
      this.init();
    },
    readDBAndInit(callbackObject = this) {
      this.$store.dispatch("setposts", []);
      try {
        readFromDB(this.$store, true)()
          .then(async () => {
            if (
              typeof callbackObject.user === "undefined" ||
              typeof callbackObject.user.user_id === "undefined"
            ) {
              this.logOff(callbackObject);
            } else {
              this.init();
            }
          })
          .catch((err) => {
            console.log("indexdb not available", err);
            this.init();
          });
      } catch (err) {
        console.log("indexdb not available", err);
        this.init();
      }
    },
    async logOff(callbackObject = this) {
      this.openToast(this.getStrings(this.strings, "LoggingOffUserInvalidSession"));

      await logOffApp();

      callbackObject.$store.dispatch("adduser", {});

      callbackObject.router.replace("/login");
    },
    listMyPages() {
      this.fetchingPageList = true;
      listGroups({ list_type: "member", pages_only: true })
        .then((res) => {
          if (res.data.status === 0) {
            this.myPageList = res.data.result.group_list;
            this.fetchingPageList = false;
          }
        })
        .catch((err) => {
          console.log("listPages - err", err);
          this.fetchingPageList = false;
        });
    },
    init() {
      this.listMyPages();
    },
    async navigatePage(tokenizedGroup) {
      await this.router.push("/page/" + tokenizedGroup);
    },
    handleNewPage() {

      this.newPageClicked = true;

      if (this.validateForm()) {
        var pageOptions = {
            name: this.form.pageName,
            group_name: this.form.pageName,
            description: this.form.pageDescription,
            is_searchable: this.form.pageSearchable,
            visibility_type: this.form.pageVisibility,
            joining_by: this.form.pageMemberJoining,
          };
        if (this.creatingNewPage) {
          createGroup(pageOptions)
            .then((res) => {

              if (res.data.status === 0) {
                this.myPageList.push(res.data.result.group);
                this.creatingNewPage = false;
                this.showMessage("Page created", "<small>New page created successfully</small>");
              }

              this.newPageClicked = false;
              this.updatingPage = false;
            })
            .catch((err) => {
              console.log("create Page - err", err);

              this.newPageClicked = false;
              this.updatingPage = false;
            });
        } else { //update page
          pageOptions['group_id'] = this.pageToUpdate.id;
          updateGroup(pageOptions)
            .then((res) => {
              if (res.data.status === 0) {
                this.showMessage("Page updated", "<small>Page updated successfully</small>");
                this.updatingPage = false;
                //update page details
                var updatedPage = this.myPageList.filter((y) => y.id === this.pageToUpdate.id);
                if (updatedPage.length > 0) {
                  updatedPage[0].group_name = this.form.pageName;
                  updatedPage[0].description = this.form.pageDescription;
                  updatedPage[0].is_searchable = this.form.pageSearchable;
                  this.form.pageName = "";
                  this.form.pageDescription = "";
                  this.form.pageSearchable = false;
                }
              } else {
                this.openToast(res.data.error.error_message);
                this.updatingPage = false;
              }

              this.newPageClicked = false;
            })
            .catch((err) => {
              console.log("update Page - err", err);
              this.openToast(this.getStrings(this.strings, "Page update error, try again"));
              this.newPageClicked = false;
            });
        }
      } else {
        this.newPageClicked = false;
        var errorMsg = this.errors.join("<br/>* ");
        errorMsg = "<small>Please correct the following error(s):<br/>* " + errorMsg + "</small>";
        this.showMessage("Page Creation Failed", errorMsg);
      }
    },
    validateForm() {
      this.errors = [];

      if (!this.form.pageName) {
        this.errors.push("Page Name is empty");
      }
      if (!this.form.pageDescription) {
        this.errors.push("Page Description is empty");
      }

      if (this.form.pageName.length < 10 || this.form.pageName.length > 50) {
        this.errors.push(
          "Page Name should have min 10 characters and maximum 50 characters"
        );
      }

      let pageWords = this.form.pageName.trim().split(/\s+/);
      if (pageWords < 2) {
        this.errors.push("Page Name should have at least 2 words");
      }

      return this.errors.length > 0 ? false : true;
    },
    onUpdatePage(page) {
      this.pageToUpdate = page;
      this.form.pageName = page.group_name;
      this.form.pageDescription = page.description;
      this.form.pageSearchable = page.is_searchable;
      this.updatingPage = true;
    },
    async confirmDeletePage(page) {
      const anAlert = await alertController.create({
        title: "Confirm Delete Post",
        message:
          '<span style="text-align: justify; text-justify: inter-word;">Deleting this page cannot be undone.<br/><small>For regulatory requirement, your post content may still be archived for sometime as per local laws.</small><br/><br/><b>Do you really want to delete this page?</b></span>',
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {
              console.log("Cancel clicked");
            },
          },
          {
            text: "Confirm",
            handler: () => {
              deactivateGroup({ group_id: page.group_id })
                .then((res) => {
                  if (res.data.status === 0) {
                    this.myPageList = this.myPageList.filter((y) => y.id !== page.id);
                    this.openToast('Page is deleted');
                  } else {
                    this.openToast(res.data.error.error_message);
                  }
                })
                .catch((err) => {
                  console.log("delete page - err", err);
                });
            }, // end handler
          },
        ],
      });
      await anAlert.present();
    },
  },
};
</script>